import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
// import {motion} from 'framer-motion';
import {motion} from 'framer-motion';


const Hero = () => {
  // const transition={type: 'spring', duration: 3}
  const transition = {type:'spring', duration:3}
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero" id='home'>

      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header/>
      {/* ----------The Best Ad--------  */}
        <div className="the-best-ad">
          <motion.div 
            initial={{left: mobile? "178px": '238px'}}
            whileInView={{left:'8px'}}
            transition={{...transition, type:'tween'}}
            >
          </motion.div>
          <span>The Best Fitness Club In The Town</span>
        </div>
        {/*---------- Hero Heading ------- */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>
                shape</span> your<span>
            </span>
          </div>
          <div>
            <span>
                ideal body
           </span>
          </div>
          <div>
            <span>
              in here we will help you to shape and 
              build your ideal body and live up your life 
              to fullest
            </span>
          </div>
        </div>



        {/*---------------Figures--------*/}
   <div className="figures">
    <div>
      <span>+140</span>
      <span>expert coachs</span>
    </div>
    <div>
      <span>+978</span>
      <span>membners joined</span>
    </div>
    <div>
      <span>56</span>
      <span>fitness programs</span>
    </div>
   </div>
   {/* -----------Hero Buttons------ */}
    <div className="hero-buttons">
      <button className='btn'>Gest Started</button>
      <button className='btn'>Learn More</button>
    </div>
    </div>
    {/* -----------Right Hero---------- */}
      <div className="right-h">
        <button className='btn'>Join Now </button>
        
        <motion.div
        initial={{right:'-1rem'}}
        whileInView={{right:'4rem'}}
        transition={{...transition, type:'tween'}}
        className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>
        {/* Hero Images */}
        <img src={hero_image} alt="" className="hero-image"/>   

        <motion.img
        initial={{right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={{...transition, type:'tween', duration:2}}

        src={hero_image_back} alt="" className="hero-image-back"/>     

{/* Calories */}
        <motion.div
         initial={{right:'37rem'}}
         whileInView={{right:'28rem'}}
         transition={{...transition, type:'tween'}}
         className="calories">
        
          <img src={calories} alt="" />
          <div>
          <span>Calories Burned</span>
          <span>220 kcal</span>
          </div>
        </motion.div>

      </div>

    </div>
  )
}

export default Hero
